import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { useLocation } from 'react-use';
import { StatusCode } from '@hungryroot/rest';
import { getApiV2Homepage } from '@hungryroot/rest/api/v2/homepage/get';
import styles from './PromoBanner.module.css';

export function PromoBanner() {
  const loc = useLocation();

  const bannerId = useMemo(() => {
    const parsed = new URLSearchParams(loc.search);
    const value = parsed.get('b');
    if (value === null) {
      return undefined;
    }
    return value;
  }, [loc.search]);
  const { data: homepageData } = useQuery({
    queryKey: ['homepage', { bannerId }],
    queryFn: async () => {
      const resp = await getApiV2Homepage({
        b: bannerId,
      });
      if (resp.status !== StatusCode.SuccessOK) {
        throw resp;
      }
      return resp.data;
    },
  });

  const bannerText = homepageData?.banner.text;

  const bannerChildren = useMemo(() => {
    if (bannerText === undefined) {
      return;
    }
    return parse(bannerText);
  }, [bannerText]);

  if (homepageData === undefined) {
    return <div className={styles.placeholder} />;
  }

  const {
    banner: { image_url: bannerImagrUrl },
  } = homepageData;

  return (
    <div className={styles.container} data-testid="promo-banner">
      <div className={styles['container-inner']}>
        {bannerImagrUrl !== null && (
          <img
            data-testid="promo-banner-decorative-image"
            alt=""
            src={bannerImagrUrl}
            className={styles.image}
          />
        )}
        <div className={styles['text-container']}>{bannerChildren}</div>
      </div>
    </div>
  );
}
