import { AUTHORIZATION_HEADER } from '../../../../src/authorization';
import { send } from '../../../../src/network';
import { type HomePageWeb } from '../../../../src/types/HomePageWeb';

type Params = {
  b?: string;
};

export async function getApiV2Homepage({ b }: Params = {}) {
  const queryParams = new URLSearchParams();

  if (b !== undefined) {
    queryParams.set('b', b);
  }

  return await send<HomePageWeb>({
    path: '/api/v2/homepage/',
    query: queryParams.size === 0 ? undefined : queryParams,
    method: 'GET',
    headers: new Headers({
      AUTHORIZATION: AUTHORIZATION_HEADER,
    }),
  });
}
